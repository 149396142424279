import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Row from "starkeydigital/React/Components/row";
import HeaderDivideText from "../../components/headerDivideText";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
import Link from "../../components/link";
export default () => {
  const LinkStyle = styled.div`
    font-family: Averta CY;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    /* identical to box height, or 250% */

    letter-spacing: -0.025em;

    cursor: pointer;
    color: #02578c;
  `;

  return (
    <Layout
      titleText="Our activities in Dubai "
      titleText2="and the Gulf States."
      slogan="We deliver leadership development programmes, workshops and 1:1 coaching to senior leaders and high potential executives for international professional services firms and corporation"
    >
      <HeaderDivideText
        style={{marginTop: "115px"}}
        header="The Dubai and Gulf States Team"
        column="col-md-8"
      />

      <p
        style={{
          textAlign: "center",
          margin: "auto",
          maxWidth: "700px",
          marginTop: "80px",
        }}
      >
        We deliver open programmes on leadership, workshops and 1:1 coaching
        with a focus on senior leaders and high potential professionals for
        international professional services firms and corporations in the Gulf
      </p>

      <Row style={{marginTop: "120px"}}>
        <div className="col-md-4">
          <StaticImage src="../../images/alison.png" alt="image" quality={90} />
        </div>
        <div className="col-md-8 mt-5 mt-md-0">
          <HeaderDivideTextLeft
            column="col"
            header="Alison - Group Managing Partner"
            text="Alison’s range of clients is broad, senior executives and their teams in large international legal, consulting and accountancy firms including Linklaters, EY and McKinsey, global banks including HSBC and RBS, together with global companies such as Cisco and Rentokil Initial. She has worked extensively in Europe, Asia, USA and Africa."
          />
          <Link to={"/coaches/1"}>
            <LinkStyle>Read More</LinkStyle>
          </Link>
        </div>
      </Row>

      <Row style={{marginTop: "120px"}}>
        <div className="col-md-8 mt-5 mt-md-0">
          <HeaderDivideTextLeft
            column="col"
            header="Tami - Partner, Dubai and the Gulf States"
            text="Tami is a qualified lawyer who has enjoyed a varied career spanning over two decades starting off in private practice (magic circle firm in London and overseas) before working as a corporate counsel for a multi-national corporation and then as a senior lecturer at law schools in the UK and Tokyo and subsequently delivering development programmes for international lawyers and business people, while developing her career consulting and executive coaching practice."
          />
          <Link to={"/coaches/5"}>
            <LinkStyle>Read More</LinkStyle>
          </Link>
        </div>
        <div className="col-md-4">
          <StaticImage src="../../images/tami.png" alt="image" quality={90} />
        </div>
      </Row>

      <Row style={{marginTop: "120px"}}>
        <div className="col-md-4">
          <StaticImage src="../../images/ian.png" alt="image" quality={90} />
        </div>
        <div className="col-md-8 mt-5 mt-md-0">
          <HeaderDivideTextLeft
            column="col"
            header="Ian - Partner, Operations and Strategy"
            text="A chartered accountant and executive coach, Ian has spent most of his career leading teams and working with ambitious men and women in professional services, international corporations and early stage businesses, most recently as a Director of the international coaching firm, ATD Partners."
          />
          <Link to={"/coaches/8"}>
            <LinkStyle>Read More</LinkStyle>
          </Link>
        </div>
      </Row>
    </Layout>
  );
};
